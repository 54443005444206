window.Rails = require("@rails/ujs");
Rails.start();

(function() {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);

  window.addEventListener("resize", resizeThrottler, false);

  var resizeTimeout;
  function resizeThrottler() {
    // ignore resize events as long as an actualResizeHandler execution is in the queue
    if ( !resizeTimeout ) {
      resizeTimeout = setTimeout(function() {
        resizeTimeout = null;
        actualResizeHandler();

        // The actualResizeHandler will execute at a rate of 15fps
      }, 66);
    }
  }

  function actualResizeHandler() {
    // handle the resize event
    document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
  }

}());

(function(window, videojs) {
  if ($('#videojs-hls-quality-selector-player').length === 0) return;
  var player = window.player = videojs('videojs-hls-quality-selector-player');
  player.hlsQualitySelector({ displayCurrentQuality: true });
  player.enableTouchActivity(true);
  player.responsive(true);
  player.fluid(true);

  player.on('ready', function() {
    player.vhs = null;
  });

  $('#videoPlay').click(function()  {

    $('#videoPlay').hide();
    $('.video__overlay').hide();
    //get global video player object
    let player = window.player
    player.play();
  });

}(window, window.videojs));
